import React, { useState, useEffect } from "react";
import { Validation } from "../../../../_helpers/validation/Validation";
import { verifyAadhar } from "../../../../_services/Arya2.0/Registration";
import { authServices } from "../../../../_services/Auth/AuthServices";
import HeadLines from "../CommonComponents/HeadLines";
import { encryptData, isMobile, isValidUdhyam } from "../helperFunc";
import { docLength, docRules, skipArray } from "./Constant";
import InfoRegardingKyc from "./InfoRegardingKyc";
import KycForm from "./KycForm";
import SkipSubmitButton from "./SkipSubmitButton";
import style from "./style.module.css";
import Loader from "../CommonComponents/Loader/Loader";
import {
  fileSizeLimit,
  fileSizeMax,
} from "../../../../_constants/Config/AryaConstants";
import { signedUrlFromDoms } from "../CommonFunctions/Function";

const KycVerification = (props) => {
  const fileType = ["image/png", "image/jpeg", "application/pdf"];
  const type =
    props.data.userType == "Farmer"
      ? "farmer"
      : props.data.userType == "Banks & NBFC’s"
      ? "banker"
      : "others";
  const [error, setError] = useState("");
  const [disable, setDisable] = useState(true);
  const [disableVerifyBtn, setDisableVerifyBtn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [msmeError, setMSMEError] = useState("");
  const [docChosen, setDocChosen] = useState(null);
  let stepCounter = 4;
  if (props.data.userType == "Banks & NBFC’s") {
    stepCounter = 3;
  }

  const submitClick = async () => {
    let errobj = {};
    errobj = Validation(props.data, docRules(docChosen));
    setError(errobj);
    let flag = 0;
    Object.keys(errobj).forEach((index) => {
      if (errobj[index] !== "") {
        flag = 1;
      }
    });

    if (props.data.msme === 1) {
      if (!isValidUdhyam(props?.data?.udyamCertificateNumber)) {
        flag = 1;
        setError((prev) => ({
          ...prev,
          ["udyamCertificateNumber"]:
            "Please enter valid udhyam certificate number",
        }));
      }
      if (flag == 0) {
        let msmeResponse = await checkMsmeCertificate();
        if (!msmeResponse) flag = 1;
      }
    }

    if (flag == 0) {
      props.setCompletedStep(stepCounter);
      props.setCurrentStep(stepCounter);
    }
  };

  const verifyClick = async (docChosen) => {
    let errobj = {};
    errobj = Validation(props.data, docRules(docChosen));
    setError(errobj);
    let flag = 0;
    Object.keys(errobj).forEach((index) => {
      if (errobj[index] !== "") {
        flag = 1;
      }
    });

    if (flag == 0) {
      if (docChosen == "Pan") {
        panVerification(props.data.Pan);
      } else if (docChosen == "Gst") {
        gstVerification(props.data.Gst);
      } else {
        aadhaarVerification(props.data.Aadhar);
      }
    }
  };

  const aadhaarVerification = async (aadhar) => {
    // setLoader(true);
    let data = {
      action_type: "change",
      aadhar_number: encryptData(aadhar),
      email: "sgfhjdfg@njsg.com",
      aadhar_front_image: props.data.aadharDoc,
      // "aadhar_back_image": props.yourDetails.aadhaarBackImageBase64
    };
    const formData = new FormData();
    (function () {
      for (const key in data) {
        formData.append(key, data[key]);
      }
    })();
    let response;
    setIsLoading(true);
    if (fileValidation(props.data.aadharDoc)) {
      response = await verifyAadhar(formData, "?v3=1");
    }
    setIsLoading(false);
    if (
      response &&
      response.data &&
      response.data.aadhar_details &&
      response.data.aadhar_details.name &&
      response.data.success_status === "1"
    ) {
      let uid = response.data.aadhar_details.uid;
      if (
        uid.substring(uid.length - 4, uid.length) !==
        aadhar.substring(aadhar.length - 4, aadhar.length)
      ) {
        setError((prev) => ({
          ...prev,
          ["aadharDoc"]: "Aadhaar Number Mismatched!",
        }));
      } else {
        props.setData((prev) => ({
          ...prev,
          ["name"]: response.data.aadhar_details.name,
          ["Pan"]: "",
          ["Gst"]: "",
          ["docChosen"]: "Aadhar",
        }));
      
        setDisable(false);
      }
      // setAadhaar(response.data.data.aadhar_details.)
      // setAadhaarverified(true);
    } else {
      if (
        response &&
        response.data &&
        response.data.aadhar_details &&
        response.data.aadhar_details.name === ""
      ) {
        setError((prev) => ({
          ...prev,
          ["aadharDoc"]: "Aadhaar Card not Verified!",
        }));
      } else if (response && response.message) {
        setError((prev) => ({
          ...prev,
          ["aadharDoc"]:
            typeof response.message === "string"
              ? response.message
              : response.message[0],
        }));
      }
    }
    // setLoader(false);
  };

  const fileValidation = (file) => {
    if (file && !fileType.includes(file?.type)) {
      setError((prev) => ({
        ...prev,
        ["aadharDoc"]:
          "The aadhar front image must be a file of type: pdf, jpeg, png.",
      }));
      return false;
    } else if (file && file?.size > fileSizeLimit) {
      setError((prev) => ({
        ...prev,
        ["aadharDoc"]: `The aadhar front image may not be greater than ${fileSizeMax}MB.`,
      }));
      //2mb
      return false;
    } else {
      return true;
    }
    // The aadhar front image must be a file of type: pdf, jpeg, bmp, png, gif, svg.
    // The aadhar front image may not be greater than 2048 kilobytes.
  };

  const gstVerification = async (gst) => {
    let dataObj = {
      gstin_number: gst,
    };
    setIsLoading(true);
    let response = await authServices.gstVerification(dataObj);
    setIsLoading(false);
    if (
      response?.data?.data?.success_status == "1" &&
      response?.data?.data?.name
    ) {
      props.setData((prev) => ({
        ...prev,
        ["name"]: response.data.data.name,
        ["Pan"]: "",
        ["Aadhar"]: "",
        ["docChosen"]: "Gst",
        ["kycPincode"]:response.data.data.principalPlacePincode,
        ["kycCity"]:response.data.data.principalPlaceCity,
        ["kycState"]:response.data.data.principalPlaceState,
        ["kycAddress"]:response.data.data.principalPlaceAddress,
      }));
      // props.setCompletedStep(stepCounter);
      // props.setCurrentStep(stepCounter);
      setDisable(false);
    } else {
      setError((prev) => ({
        ...prev,
        ["Gst"]: response?.data?.message,
      }));
    }
  };

  const panVerification = async (p_number) => {
    // setLoader(true);
    let dataObj = {
      action_type: "add",
      pan: p_number,
      type: "B",
      email: null,
      image: null,
      state: null,
    };
    setIsLoading(true);
    let response = await authServices.panVerification(dataObj, "?v3=1");
    setIsLoading(false);
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.first_name &&
      response.data.data.success_status !== "0"
    ) {
      props.setData((prev) => ({
        ...prev,
        ["name"]: response.data.data.first_name,
        ["Gst"]: "",
        ["Aadhar"]: "",
        ["docChosen"]: "Pan",
      }));
   
      // props.setCompletedStep(stepCounter);
      // props.setCurrentStep(stepCounter);
      setDisable(false);
    } else {
      setDisable(true);

      if (response.data.message) {
        setError((prev) => ({
          ...prev,
          ["Pan"]: response.data.message,
        }));
      }
    }
  };
  const checkMsmeCertificate = async () => {
    setIsLoading(true);
    if (props.data.udyamCertificate) {
      const setErrorCallBack = (errorText = "") => {
        setMSMEError(errorText);
        setError((prev) => ({
          ...prev,
          ["udyamCertificate"]: errorText,
        }));
        return false;
      };
      const signedUrl = await signedUrlFromDoms(
        props.data?.udyamCertificate,
        setErrorCallBack
      );
      setIsLoading(false);
      if (signedUrl) {
        props.setData((prev) => ({
          ...prev,
          ["udyamCertificateSignedUrl"]: signedUrl,
        }));
        return true;
      }
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    if (value.toString().length <= docLength[name]) {
      props.setData((prev) => ({
        ...prev,
        [name]: value.toUpperCase(),
        docChosen: name,
      }));
    }
    if (value.toString().length >= docLength[name]) {
      setDisableVerifyBtn(false);
    } else {
      setDisableVerifyBtn(true);
    }

    // setDocChosen(name);
  };

  useEffect(() => {
    if (props.data.msme === 1) {
      if (
        (props.data.Pan || props.data.Gst) &&
        props.data.udyamCertificate &&
        props.data.udyamCertificateNumber
      ) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }
  }, [
    props.data.pan,
    props.data.Gst,
    props.data.udyamCertificate,
    props.data.udyamCertificateNumber,
  ]);
  useEffect(() => {
    props.ScrollToTop();
  }, []);
  return (
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      <div
        className={`${
          isMobile
            ? "font-size12 line-height14 letterSpacing025 margin-top16"
            : ` font-size20 line-height23 letterSpacing001 margin-top24 padding-bottom8`
        } d-flex font-weight400 margin-left16 `}
      >
        <HeadLines text="Selected Role" />
        :&nbsp;
        <HeadLines
          text={props.data.userType}
          className={`${style["txt-uppercase"]} font-weight600`}
        />
      </div>
      <InfoRegardingKyc type={type} />
      <KycForm
        type={type}
        onChange={onChange}
        data={props.data}
        setData={props.setData}
        error={error}
        docChosen={docChosen}
        setMSMEError={setMSMEError}
        msmeError={msmeError}
        setDocChosen={setDocChosen}
        disable={disableVerifyBtn}
        verifyClick={verifyClick}
      />
      <SkipSubmitButton
        submitClick={submitClick}
        buttonEnable={!disable}
        skipClick={() => {
          props.updateKycNotification();
          props.setShow(false);
        }}
        noSkip={props.disableSkip}
        skipText={skipArray[2]}
        setBlurBackGround={props.setBlurBackGround}
      />
    </>
  );
};
export default KycVerification;
