import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useAllUserData from "../../CustomHooks/useAllUserData";
import HeadLines from "../../CommonComponents/HeadLines";
import style from "./style.module.css";
import { isMobile } from "../../helperFunc";
import InputFieldWithTitle from "../../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import CustomReactSelect from "../../CommonComponents/CustomReactSelect/CustomReactSelect";
import TextAreaWithTitle from "../../CommonComponents/TextAreaWithTitle/TextAreaWithTitle";
import { errorInfoIcon } from "../../icon_assets";
import CustomCheckBox from "../../CommonComponents/CustomCheckBox/CustomCheckBox";
import Button from "../../CommonComponents/Button";
import HeadLinesSpan from "../../CommonComponents/HeadlinesSpan";
import { headers, keysToBeUsedInTable } from "./constant";
import CommonTable from "./CommonTable/CommonTable";
import { CommonApiService } from "../../../../../_services";
import MobileTableView from "./MobileTabView/MobileTabView";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { titleCase } from "../../CommonFunctions/Function";
import { useClickOutside } from "../../CustomHooks/useClickOutside";
const BillingInfoForm = ({
  onChange = () => {},
  data,
  error,
  pincode,
  state,
  permanantAddressLine1,
  setFormValue,
  formValue,
  handleForm = () => {},
  setIsLoading = () => {},
  handlePincode,
  setContactPersonForm = () => {},
  contactPersonForm,
  gstContactPersonDetails,
  city,
  isNewGst = false,
}) => {
  const { t: translation } = useTranslation();
  const [contactPersons, setContactPersons] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [contactTableData, setContactTableData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newContact, setNewContact] = useState({
    name: "",
    phone: "",
    email: "",
    department: "",
    designation: "",
    isPrimary: true,
    status: "ACTIVE",
    billingCommunication: true,
  });
  const allUserData = useAllUserData();
  const [sameAsUserDetails, setSameAsUserDetails] = useState(false);

  const [newUser, setNewUser] = useState({
    userName: allUserData?.member?.entity_name
      ? allUserData?.member?.entity_name
      : "",
    userPhone: allUserData?.member?.phone_number,
    userEmail: allUserData?.member?.email ? allUserData?.member?.email : "",
    comLang: "",
    contentLang: "",
  });

  const [userErrors, setUserErrors] = useState({});
  const [contactErros, setContactErrors] = useState({});

  const filterData = (userList) => {
    if (!Array.isArray(userList)) {
      console.error("Invalid data:", userList);
      return [];
    }

    return userList.map((ele, index) => {
      let newDataObj = {};
      headers.forEach((header) => {
        if (header === "Contact Person Name") {
          const nameWithPrimary = ele.isPrimary ? (
            <span>
              {ele[keysToBeUsedInTable[header]]}{" "}
              <span className={style.primaryLabel}>Primary</span>
            </span>
          ) : (
            ele[keysToBeUsedInTable[header]]
          );
          newDataObj[header] = {
            label: nameWithPrimary || "--",
          };
        } else {
          newDataObj[header] = {
            label: ele[keysToBeUsedInTable[header]] || "--",
          };
        }
      });
      return newDataObj;
    });
  };

  const handleSwitchToggle = (checked, index) => {
    setContactPersons((prevContacts) => {
      const updatedContacts = [...prevContacts];

      if (index < 0 || index >= updatedContacts.length) {
        console.error(
          `Invalid index ${index} for contactPersons`,
          updatedContacts
        );
        return prevContacts;
      }

      updatedContacts[index] = {
        ...updatedContacts[index],
        billingCommunication: !updatedContacts[index].billingCommunication,
      };

      setContactTableData(filterData(updatedContacts));
      return updatedContacts;
    });
  };

  const handleEditContact = (index) => {
    setNewContact(contactPersons[index]);
  };

  const validateContact = (contact) => {
    const newErrors = {};

    if (!contact?.name?.trim()) newErrors.name = "Name is required.";
    if (!contact?.phone?.trim() || !/^[6-9][0-9]{9}$/.test(contact.phone))
      newErrors.phone = "Valid phone number is required.";
    if (
      !contact.email?.trim() ||
      !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(contact.email)
    )
      newErrors.email = "Valid email is required.";

    return newErrors;
  };

  const isDuplicateContact = (phone, type) =>
    contactPersons.some((contact) => contact[type] === phone);

  const handleAddContact = () => {
    const validationErrors = validateContact(newContact);

    if (Object.keys(validationErrors).length > 0) {
      setContactErrors(validationErrors);
      return;
    }

    // if (isDuplicateContact(newContact.phone, newContact.email)) {
    //   setContactErrors({
    //     phone: "This contact person is already created.",
    //     email: "This contact person is already created.",
    //   });
    //   return;
    // }

    if (isDuplicateContact(newContact.phone, "phone")) {
      setContactErrors({
        phone: "This contact person is already created.",
      });
      return;
    }
    if (isDuplicateContact(newContact.email, "email")) {
      setContactErrors({
        email: "This contact person is already created.",
      });
      return;
    }

    const updatedContacts = [...contactPersons];
    if (newContact.isPrimary) {
      updatedContacts.forEach((contact) => (contact.isPrimary = false));
    }

    updatedContacts.push(newContact);

    setContactPersons(updatedContacts);
    setContactTableData(filterData(updatedContacts));
    setContactPersonForm(updatedContacts);
    setNewContact({
      name: "",
      phone: "",
      email: "",
      department: "",
      designation: "",
      isPrimary: false,
      billingCommunication: true,
      status: "ACTIVE",
    });
    setSameAsUserDetails(false);
    setContactErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "sameAsUserDetails") {
      setSameAsUserDetails(checked);
      if (checked) {
        // Set contact details same as user details
        setNewContact((prev) => ({
          ...prev,
          name: newUser.userName,
          phone: newUser.userPhone,
          email: newUser.userEmail,
        }));
      } else {
        setNewContact((prev) => ({
          ...prev,
          name: "",
          phone: "",
          email: "",
        }));
      }
    } else {
      setNewContact((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  useEffect(() => {
    if (contactPersons?.length > 0) {
      let isPrimaryExist =
        contactPersons && contactPersons?.length > 0
          ? contactPersons?.find((contact) => contact.isPrimary)
          : false;
      setIsDisable(!isPrimaryExist);
    } else {
      if (!newContact?.isPrimary) {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
    }
  }, [contactPersons, newContact]);

  const validateUser = (user) => {
    const newErrors = {};
    if (!user.userName.trim()) newErrors.userName = "Name is required.";
    if (
      user.userEmail.trim() &&
      !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(user.userEmail)
    ) {
      newErrors.userEmail = "Enter a valid email address.";
    }

    return newErrors;
  };

  const handleUserInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) return;

    setNewUser((prev) => {
      const updatedUser = {
        ...prev,
        [name]: value,
      };

      const validationErrors = validateUser(updatedUser);
      if (Object.keys(validationErrors).length > 0) {
        setUserErrors(validationErrors);
      } else {
        setUserErrors({});
      }

      return updatedUser;
    });
  };
  const handleSetAsPrimary = (index) => {
    setContactPersons((prevContacts) => {
      const updatedContacts = prevContacts.map((contact, i) => ({
        ...contact,
        isPrimary: i === index, // Set only the clicked contact as primary
      }));
      setContactTableData(filterData(updatedContacts));
      return updatedContacts;
    });
  };
  const handleStatusChange = (index) => {
    setContactPersons((prevContacts) => {
      const updatedContacts = prevContacts.map((contact, i) => {
        if (i === index) {
          // Toggle the status between ACTIVE and INACTIVE
          return {
            ...contact,
            status: contact.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
          };
        }
        return contact;
      });
      setContactTableData(filterData(updatedContacts));
      return updatedContacts; // Update the state
    });
  };
  const handleBillingSwitchChange = (checked, rowIndex) => {
    setContactPersons((prevData) => {
      if (rowIndex === null) {
        // Toggle for all rows when header switch is used
        return prevData.map((row) => ({
          ...row,
          billingCommunication: checked,
        }));
      } else {
        // Toggle for specific row
        return prevData.map((row, index) =>
          index === rowIndex ? { ...row, billingCommunication: checked } : row
        );
      }
    });
  };
  useEffect(() => {
    if (gstContactPersonDetails?.length > 0) {
      if (gstContactPersonDetails) {
        const newContactPersonForm = gstContactPersonDetails.map((item) => ({
          ...item,
          isPrimary: item.isPrimary.toString().toLowerCase() === "true",
          billingCommunication:
            item.billingCommunication.toString().toLowerCase() === "true",
        }));
        setContactPersonForm(newContactPersonForm);
        setContactPersons(newContactPersonForm);
        setContactTableData(filterData(newContactPersonForm));
      }
    }
  }, [gstContactPersonDetails]);
  return (
    <>
      {/* *************** Add User **********************************/}
      <HeadLines
        text="Billing Information"
        className={`${
          isMobile
            ? `font-weight500 font-size16 line-height19 letterSpacing025 ${style["colorLightBlack"]}`
            : `font-weight500 font-size20 line-height24 letterSpacing001 colorDark1`
        } `}
      />
      <div
        className={`${
          isNewGst && isMobile
            ? `${style["mobBillingDiv"]}`
            : `${style["billingdiV"]}`
        } `}
      >
        <div className={"d-flex gap20"}>
          <InputFieldWithTitle
            showVerifyText={false}
            titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
              isMobile ? `font-weight500` : `font-weight600`
            }`}
            title={"Trade Name"}
            disabled={true}
            isCompulsory={false}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? `font-size14 line-height16`
                : "font-size16 line-height19"
            } letterSpacing001`}
            disableInputClassName={`input-style-disable`}
            errorInputClassName={"input-style-error "}
            errorClassName={"input-error-style margin-top4"}
            showError={error?.entity_name ? error.entity_name : ""}
            errorIcon={errorInfoIcon}
            value={formValue?.entity_name}
            name="state"
            className={isMobile ? "w-100" : "w-50"}
            marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
          />
          {!isNewGst && (
            <InputFieldWithTitle
              showVerifyText={false}
              titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
                isMobile ? `font-weight500` : `font-weight600`
              }`}
              title={"Billing Name"}
              disabled={true}
              isCompulsory={false}
              compulsoryStarClassName="line-height14"
              inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
                style["inputBorder"]
              } font-weight400 ${
                isMobile
                  ? "font-size14 line-height16"
                  : "font-size16 line-height19"
              } letterSpacing001`}
              disableInputClassName={`input-style-disable`}
              errorInputClassName={"input-style-error "}
              errorClassName={"input-error-style margin-top4"}
              showError={error?.billing_name ? error.billing_name : ""}
              errorIcon={errorInfoIcon}
              value={formValue?.billing_name}
              name="state"
              className={isMobile ? "w-100" : "w-50"}
              marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
            />
          )}
        </div>

        <div
          className={`${
            isMobile
              ? `${style["mob-Billing-style"]}`
              : `${style["billingdiV"]} mt-0`
          } `}
        >
          <HeadLines
            text="Billing Address"
            className={`${
              isMobile ? "margin-bottom16" : style["marginBottom22"]
            } ${
              isMobile
                ? `font-weight400 font-size14 line-height16 letterSpacing025 colorDark5`
                : `font-weight500 font-size18 line-height21 letterSpacing001 ${style["colorLightBlack"]}`
            }`}
          />

          <InputFieldWithTitle
            titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
              isMobile ? `font-weight500` : `font-weight600`
            }`}
            title={"Pincode"}
            disabled={false}
            isCompulsory={true}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? `font-size14 line-height16 ${style["h-40"]}`
                : "font-size16 line-height19"
            } letterSpacing001`}
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style margin-top4"}
            showError={error?.pincode ? error.pincode : ""}
            errorIcon={errorInfoIcon}
            value={formValue?.pincode}
            name="pincode"
            onChange={handlePincode}
            marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
          />

          <div
            className={`d-flex ${isMobile ? "gap16 flex-d-col" : "width-100"}`}
          >
            <InputFieldWithTitle
              showVerifyText={false}
              titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
                isMobile ? `font-weight500` : `font-weight600`
              }`}
              title={"City"}
              disabled={false}
              isCompulsory={true}
              compulsoryStarClassName="line-height14"
              inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
                style["inputBorder"]
              } font-weight400 ${
                isMobile
                  ? `font-size14 line-height16 ${style["h-40"]}`
                  : "font-size16 line-height19"
              } letterSpacing001`}
              disableInputClassName={`input-style-disable`}
              errorInputClassName={"input-style-error"}
              errorClassName={"input-error-style margin-top4"}
              showError={error?.city ? error.city : ""}
              errorIcon={errorInfoIcon}
              value={formValue?.city}
              name="city"
              className={isMobile ? "" : "width-100"}
              marginCSS={isMobile ? "" : "margin-bottom24"}
              onChange={(e) => handleForm(e)}
            />
            <InputFieldWithTitle
              showVerifyText={false}
              titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
                isMobile ? `font-weight500` : `font-weight600`
              }`}
              title={"State"}
              disabled={true}
              isCompulsory={true}
              compulsoryStarClassName="line-height14"
              inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
                style["inputBorder"]
              } font-weight400 ${
                isMobile
                  ? `font-size14 line-height16 ${style["h-40"]}`
                  : "font-size16 line-height19"
              } letterSpacing001`}
              disableInputClassName={`input-style-disable`}
              errorInputClassName={"input-style-error "}
              errorClassName={"input-error-style margin-top4"}
              showError={error?.state_name ? error.state_name : ""}
              errorIcon={errorInfoIcon}
              value={formValue?.state_name}
              name="state"
              className={isMobile ? "" : "width-100 margin-left20"}
              marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
            />
          </div>

          <InputFieldWithTitle
            showVerifyText={false}
            className={isMobile ? "" : "width-100"}
            titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
              isMobile ? `font-weight500` : `font-weight600`
            }`}
            title={"Full Address"}
            disabled={false}
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? `font-size14 line-height16 ${style["h-40"]}`
                : "font-size16 line-height19"
            } letterSpacing001`}
            disableInputClassName={`input-style-disable`}
            errorInputClassName={"input-style-error "}
            errorClassName={"input-error-style margin-top4"}
            showError={""}
            name="address"
            errorIcon={errorInfoIcon}
            marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
            value={formValue?.address}
            onChange={(e) => handleForm(e)}
          />
        </div>
        <div className={isMobile ? "" : style["lineCss2"]} />

        {/* *************** Contact Person**********************************/}

        <div className={`${isMobile ? "margin-top24" : ""}`}>
          <HeadLines
            text="Contact Persons"
            className={` ${
              isMobile
                ? `font-weight500 font-size14 line-height16 letterSpacing025 colorDark5 margin-bottom8`
                : `font-weight500 font-size16 line-height19 letterSpacing001 ${style["colorLightBlack"]} margin-bottom12`
            }`}
          />
          <HeadLines
            text="This person will receive billing related communication"
            className={`${
              isMobile
                ? `font-weight500 font-size14 line-height16 letterSpacing025 colorDark9 margin-bottom8`
                : `font-weight500 font-size16 line-height19 letterSpacing001 colorDark9 margin-bottom12`
            }`}
          />
          {!isNewGst && (
            <div
              className={`d-flex align-items-center gap4 margin-top16 margin-bottom16`}
            >
              <CustomCheckBox
                id="same_as_user_details"
                inputProps={{
                  name: "sameAsUserDetails",
                  checked: sameAsUserDetails,
                  onChange: handleInputChange,
                }}
              />

              <HeadLines
                className={`font-weight400 font-size16 line-height19 colorDark5 letterSpacing05`}
                text={`Same as user details`}
              />
            </div>
          )}

          <div
            className={
              isMobile
                ? `d-flex align-items-center flex-column gap16`
                : style["contactPersonContainer"]
            }
          >
            <InputFieldWithTitle
              titleClassName={`${
                isMobile
                  ? `font-weight500 font-size14 line-height16 letterSpacing05 colorDark9`
                  : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
              }`}
              className={isMobile ? `width-100` : ``}
              title={"Contact Person Name"}
              disabled={false}
              isCompulsory={true}
              compulsoryStarClassName="line-height14"
              inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
                style["inputBorder"]
              } font-weight400 ${
                isMobile
                  ? `font-size14 line-height16 ${style["h-40"]}`
                  : "font-size16 line-height19"
              } letterSpacing001`}
              errorInputClassName={"input-style-error"}
              errorClassName={"input-error-style margin-top4"}
              showError={contactErros?.name ? contactErros?.name : ""}
              errorIcon={errorInfoIcon}
              value={newContact.name}
              name="name"
              onChange={handleInputChange}
              marginCSS={style["marginb0"]}
            />

            <InputFieldWithTitle
              titleClassName={`${
                isMobile
                  ? `font-weight500 font-size14 line-height16 letterSpacing05 colorDark9`
                  : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
              }`}
              className={isMobile ? `width-100` : ``}
              title={"Phone"}
              disabled={false}
              isCompulsory={true}
              compulsoryStarClassName="line-height14"
              inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
                style["inputBorder"]
              } font-weight400 ${
                isMobile
                  ? `font-size14 line-height16 ${style["h-40"]}`
                  : "font-size16 line-height19"
              } letterSpacing001`}
              errorInputClassName={"input-style-error"}
              errorClassName={"input-error-style margin-top4"}
              showError={contactErros?.phone ? contactErros?.phone : ""}
              errorIcon={errorInfoIcon}
              value={newContact.phone}
              name="phone"
              onChange={handleInputChange}
              marginCSS={style["marginb0"]}
              maxLength={10}
              minLength={10}
              type="number"
            />
            <InputFieldWithTitle
              titleClassName={`${
                isMobile
                  ? `font-weight500 font-size14 line-height16 letterSpacing05 colorDark9`
                  : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
              }`}
              className={isMobile ? `width-100` : ``}
              title={"Email"}
              disabled={false}
              isCompulsory={true}
              compulsoryStarClassName="line-height14"
              inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
                style["inputBorder"]
              } font-weight400 ${
                isMobile
                  ? `font-size14 line-height16 ${style["h-40"]}`
                  : "font-size16 line-height19"
              } letterSpacing001`}
              errorInputClassName={"input-style-error"}
              errorClassName={"input-error-style margin-top4"}
              showError={contactErros?.email ? contactErros?.email : ""}
              errorIcon={errorInfoIcon}
              value={newContact.email}
              name="email"
              onChange={handleInputChange}
              marginCSS={style["marginb0"]}
            />
            <InputFieldWithTitle
              titleClassName={`${
                isMobile
                  ? `font-weight500 font-size14 line-height16 letterSpacing05 colorDark9`
                  : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
              }`}
              className={isMobile ? `width-100` : ``}
              title={"Department"}
              disabled={false}
              isCompulsory={false}
              compulsoryStarClassName="line-height14"
              inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
                style["inputBorder"]
              } font-weight400 ${
                isMobile
                  ? `font-size14 line-height16 ${style["h-40"]}`
                  : "font-size16 line-height19"
              } letterSpacing001`}
              value={newContact.department}
              name="department"
              onChange={handleInputChange}
              marginCSS={style["marginb0"]}
            />
            <InputFieldWithTitle
              titleClassName={`${
                isMobile
                  ? `font-weight500 font-size14 line-height16 letterSpacing05 colorDark9`
                  : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
              }`}
              className={isMobile ? `width-100` : ``}
              title={"Designation"}
              disabled={false}
              isCompulsory={false}
              compulsoryStarClassName="line-height14"
              inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
                style["inputBorder"]
              } font-weight400 ${
                isMobile
                  ? `font-size14 line-height16 ${style["h-40"]}`
                  : "font-size16 line-height19"
              } letterSpacing001`}
              value={newContact.designation}
              name="designation"
              onChange={handleInputChange}
              marginCSS={style["marginb0"]}
            />
          </div>

          <div className={`d-flex align-items-center gap4 margin-top16`}>
            <CustomCheckBox
              id="primary-contact-checkbox"
              className={`${isMobile && style["mt-2"]} mb-auto`}
              inputProps={{
                name: "isPrimary",
                checked: newContact.isPrimary,
                onChange: handleInputChange,
              }}
            />
            <HeadLines
              className={`${
                isMobile
                  ? `font-weight500 font-size16 line-height19 colorDark5`
                  : "font-weight400 font-size16 line-height19 colorDark5 letterSpacing05"
              }`}
              text={`Make this contact person as the primary contact person`}
            />
          </div>

          <div className="d-flex justify-content-center">
            <Button
              onClick={handleAddContact}
              className={`${isDisable && `${style["grayButton"]}`} 
                ${isMobile ? style["mobAddButton"] : style["addButton"]}`}
              title="Add"
              disabled={isDisable}
            />
          </div>
        </div>

        {isMobile ? (
          <MobileTableView
            data={contactTableData}
            isLoading={false}
            headers={headers}
            noDataFound={true}
            handleBillingSwitchChange={handleSwitchToggle}
            actionComponents={contactTableData.map((_, index) => (
              <ActionButton
                key={index}
                index={index}
                setContactPersons={setContactPersons}
                contactPersons={contactPersons}
                setNewContact={setNewContact}
                setIsEditing={setIsEditing}
                setEditingIndex={setEditingIndex}
                handleSetAsPrimary={handleSetAsPrimary}
                handleStatusChange={handleStatusChange}
              />
            ))}
          />
        ) : (
          <span className={style.contactTable}>
            <CommonTable
              headers={headers}
              subLabel={false}
              tableClassName={style.tableClassName}
              data={contactTableData}
              tableCell={style.tableCell}
              handleBillingSwitchChange={handleSwitchToggle}
              contactPersons={contactPersons}
              actionComponents={contactTableData.map((_, index) => (
                <ActionButton
                  key={index}
                  index={index}
                  setContactPersons={setContactPersons}
                  contactPersons={contactPersons}
                  setNewContact={setNewContact}
                  setIsEditing={setIsEditing}
                  setEditingIndex={setEditingIndex}
                  handleSetAsPrimary={handleSetAsPrimary}
                  handleStatusChange={handleStatusChange}
                />
              ))}
            />
          </span>
        )}
      </div>
    </>
  );
};
export default BillingInfoForm;
const ActionButton = ({
  setContactPersons,
  contactPersons,
  setNewContact,
  index,
  setIsEditing,
  setEditingIndex,
  handleSetAsPrimary,
  handleStatusChange,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const divRef = useRef();
  const flag = useClickOutside(divRef);
  useEffect(() => {
    if (flag && !isMobile) {
      setShowOverlay(false);
    }
  }, [flag]);
  const handleEditClick = () => {
    setNewContact(contactPersons[index]);
    setIsEditing(true);
    setEditingIndex(index);
    setShowOverlay(false);
  };

  const handlePrimaryClick = () => {
    handleSetAsPrimary(index);
    setShowOverlay(false);
  };
  return (
    <div ref={divRef}>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        show={showOverlay}
        onToggle={(visible) => setShowOverlay(visible)}
        overlay={
          <Popover>
            <Popover.Content className="">
              <Button
                onClick={handlePrimaryClick}
                className={`${style["setButton"]} ${style["backWhite"]}`}
                title={"Set As Primary"}
              />
            </Popover.Content>
            <Popover.Content>
              <Button
                onClick={() => {
                  handleStatusChange(index);
                  setShowOverlay(false);
                }}
                className={`${style["setButton"]} ${style["backWhite"]}`}
                title={titleCase(
                  contactPersons[index]?.status === "ACTIVE"
                    ? "INACTIVE"
                    : "ACTIVE"
                )}
              />
            </Popover.Content>
          </Popover>
        }
      >
        <Button
          variant="secondary"
          onClick={() => setShowOverlay(!showOverlay)}
          className={style["backWhite"]}
        >
          <div className={style["dot"]}></div>
          <div className={style["dot"]}></div>
          <div className={style["dot"]}></div>
        </Button>
      </OverlayTrigger>
    </div>
  );
};
