import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../CommonComponents/Header";
import HeaderBackground from "../CommonComponents/HeaderBackground";
import { backArrow } from "../icon_assets";
import ProfileCard from "../Profile/ProfileCard";
import s from "./styles.module.css";
import "../commonStyle.css";
import AccountSettingForm from "./AccountSettingForm";
import { useHistory } from "react-router";
import useUserDetails from "../CustomHooks/useUserDetails";
import StickyFooter from "../StickyFooter/StickyFooter";
import VerifyEmailModal from "./VerifyEmailModal";
import Loader from "../CommonComponents/Loader/Loader";
import { Validation } from "../../../../_helpers/validation/Validation";
import {
  getUserDetails,
  postUserDetails,
  verifyEmailOTP,
} from "../../../../_services/Arya2.0/AccountSettings";
import { customToastr } from "../CustomToastr";
import tcs from "../CustomToastr/customToastrStyles.module.css";
import { getCityStateFromPincode } from "../../../../_services/Arya2.0/CommonServices";
import DesktopHeader from "../DesktopCommonComponents/DesktopHeader/DesktopHeader";
import { isMobile } from "../helperFunc";
import DesktopFooter from "../DesktopCommonComponents/DesktopFooter/DesktopFooter";
import BreadcrumbUI from "../DesktopCommonComponents/Breadcrumb/BreadcrumbUI";
import { LoginServices } from "../../../../_services/Arya2.0/Login/LoginServices";
import EmailLinkSentPopUp from "../Registration/EmailLinkSentPopUp";

const initialErrorStates = {
  email: "",
  pincode: "",
  state: "",
  city: "",
};

const emailPattern =
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
const reEmail = new RegExp(emailPattern);
const AccountSettings = (props) => {
  const { hideHeaderFooter = false } = props;
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [isValidEmailRegex, setIsValidEmailRegex] = useState(false);
  const userDetails = useUserDetails();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const userDetailsRef = useRef({});
  const [showEmailSentPopUp, setShowEmailSentPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [accountSettingFormStates, setAccountSettingFormStates] = useState({
    pan: "",
    tan: "",
    gst: "",
    hideDetails: true,
    phn: "",
    email: "",
    pincode: "",
    state: { id: "", name: "" },
    city: { id: "", name: "" },
    rmName: "",
    permanantAddressLine1: "",
    permanantAddressLine2: "",
    isEmailVerified: false,
    addressFieldFromApi: {},
  });
  const [disableSave, setDisableSave] = useState(true);

  const validationRules = [
    {
      field_name: "email",
      label: "Email",
      type: "email",
      isRequired: accountSettingFormStates.email ? true : false,
      // errorMsg: "Testing Msg",
    },

    {
      field_name: "pincode",
      label: "Pincode",
      type: "number",
      isRequired: accountSettingFormStates.pincode.length > 0 ? true : false,
      errorMsg: "Enter Valid Pincode",
      min: 6,
      max: 6,
    },
    {
      field_name: "city",
      label: "City",
      type: "string",
      isRequired:
        accountSettingFormStates.pincode.length > 0 &&
        !accountSettingFormStates.city.name
          ? true
          : false,
    },
    {
      field_name: "state",
      label: "State",
      type: "string",
      isRequired:
        accountSettingFormStates.pincode.length > 0 &&
        !accountSettingFormStates.state.name
          ? true
          : false,
    },
  ];

  let getUserDetailsAPI = async () => {
    try {
      setIsLoading(true);

      const res = await getUserDetails();
      console.log("res", res);
      if (res?.status_code === 200) {
        setAccountSettingFormStates({
          addressFieldFromApi: res?.data?.address || {},
          isEmailVerified: res.data.email_verify,
          childEntities: res.data.childEntities,
          contactDetails: res.data.contactDetails,
          usersData: res.data.usersData,

          pan: res.data.pan_number,
          tan: res.data.tan,
          gst: res.data.gstin_number,
          hideDetails:
            res?.data?.is_catalog_hide_details === 1 ||
            res?.data?.is_catalog_hide_details === "1",
          email: res.data.email,
          emailVerified: res.data.email_verify,
          pincode: res?.data?.address?.pincode,
          state: {
            id: res?.data?.address?.state,
            name: res?.data?.address?.state_name,
          },
          city: {
            id: res?.data?.address?.district,
            name: res?.data?.address?.district_name,
          },
          rmName: res?.data?.rm_name,
          rmEmail: res?.data?.rm_email,
          rmPhone: res?.data?.rm_phone,
          permanantAddressLine1: res?.data?.address?.address_line1,
          permanantAddressLine2: res?.data?.address?.address_line2,
          first_name: res?.data?.first_name,
          gst_verified: res?.data?.gst_verified,
          id: res?.data?.id,
          isKyc: res?.data?.kyc,
          pan_verified: res?.data?.pan_verified,
          phone_number: res?.data?.phone_number,
          tan_verified: res?.data?.tan_verified,
          kycAddress:res?.data?.entity_address?.addressLine1 || "",
          kycState:res?.data?.entity_address?.state || "",
          kycCity:res?.data?.entity_address?.city || "",
          kycPincode:res?.data?.entity_address?.pincode || "",
          entityEmail:res?.data?.entity_email || "",
          entityPhone:res?.data?.entity_phone || "",
          apiEntityEmail:res?.data?.entity_email || "",
          apiEntityPhone:res?.data?.entity_phone || "",
        });
        userDetailsRef.current = {
          addressFieldFromApi: res?.data?.address || {},
          isEmailVerified: res?.data.email_verify,
          pan: res?.data.pan_number,
          tan: res?.data.tan,
          gst: res?.data.gstin_number,
          hideDetails:
            res?.data?.is_catalog_hide_details === 1 ||
            res?.data?.is_catalog_hide_details === "1",
          email: res.data.email,
          emailVerified: res.data.email_verify,
          pincode: res?.data?.address?.pincode,
          state: {
            id: res?.data?.address?.state,
            name: res?.data?.address?.state_name,
          },
          city: {
            id: res?.data?.address?.district,
            name: res?.data?.address?.district_name,
          },
          rmName: res?.data?.rm_name,
          rmEmail: res?.data?.rm_email,
          rmPhone: res?.data?.rm_phone,
          permanantAddressLine1: res?.data?.address?.address_line1,
          permanantAddressLine2: res?.data?.address?.address_line2,
          first_name: res?.data?.first_name,
          gst_verified: res?.data?.gst_verified,
          id: res?.data?.id,
          isKyc: res?.data?.kyc,
          pan_verified: res?.data?.pan_verified,
          phone_number: res?.data?.phone_number,
          tan_verified: res?.data?.tan_verified,
          childEntities: res?.data?.childEntities,
          contactDetails: res?.data?.contactDetails,
          usersData: res?.data?.usersData,
          
        };
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    document.body?.classList.add("padding-top-unset");
    getUserDetailsAPI();
  }, []);

  useMemo(() => {
    if (Object.keys(userDetailsRef.current).length) {
      for (const key in userDetailsRef.current) {
        if (key === "state" || key === "city") {
          if (
            userDetailsRef.current[key].name !==
            accountSettingFormStates[key].name
          ) {
            setDisableSave(false);
            break;
          }
        } else if (
          userDetailsRef.current[key] !== accountSettingFormStates[key]
        ) {
          setDisableSave(false);
          break;
        } else {
          setDisableSave(true);
        }
      }
    } else {
      setDisableSave(true);
    }
  }, [accountSettingFormStates]);

  const updateUserDetails = async () => {
    let payload = {
      email: accountSettingFormStates.email,
      is_catalog_hide_details: accountSettingFormStates.hideDetails ? 1 : 0,
      address: {
        ...accountSettingFormStates.addressFieldFromApi,
        address_line1: accountSettingFormStates?.permanantAddressLine1,
        address_line2: accountSettingFormStates?.permanantAddressLine2,
        district: accountSettingFormStates?.city?.id,
        pincode: accountSettingFormStates?.pincode,
        state: accountSettingFormStates?.state?.id,
        district_name: accountSettingFormStates?.city?.name,
        state_name: accountSettingFormStates?.state?.name,
      },
      email_verify: accountSettingFormStates?.isEmailVerified,
      first_name: accountSettingFormStates?.first_name,
      // gst_verified: accountSettingFormStates?.gst_verified,
      // gstin_number: accountSettingFormStates?.gst,
      id: accountSettingFormStates?.id,
      isKyc: accountSettingFormStates?.isKyc,
      // pan_number: accountSettingFormStates?.pan,
      // pan_verified: accountSettingFormStates?.pan_verified,
      phone_number: accountSettingFormStates?.phone_number,
      // tan: accountSettingFormStates?.tan,
      // tan_verified: accountSettingFormStates?.tan_verified,
    };
    return await postUserDetails(payload);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await updateUserDetails();
      if (res.status_code === 200) {
        customToastr({
          text: "Details saved successfully",
          type: "success",
          positionClass: tcs["toast-top-center1"],
          toastClass: tcs["custom-toastr"],
          timeOut: 1,
        });
        await getUserDetailsAPI();
        setDisableSave(true);
      } else {
        if (
          (res?.status_code > 400 && res?.status_code <= 418) ||
          res?.status_code >= 420
        ) {
          setErrorMessage(
            res?.message
              ? Array.isArray(res?.message)
                ? res?.message[0]
                : res.message
              : res?.msg
              ? res?.msg
              : ""
          );
        }
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSwitchChange = () => {
    setAccountSettingFormStates((prev) => ({
      ...prev,
      hideDetails: !prev.hideDetails,
    }));
  };
  const handleEmailChange = (e) => {
    setAccountSettingFormErrors(initialErrorStates);

    const { value } = e.target;

    if (value && reEmail.exec(value) != null) {
      setIsValidEmailRegex(true);
    } else setIsValidEmailRegex(false);
    setAccountSettingFormStates((prev) => ({ ...prev, email: value }));
  };
  const handlePincode = (e) => {
    setAccountSettingFormErrors(initialErrorStates);

    const { value } = e.target;
    if (String(value).length <= 6)
      setAccountSettingFormStates((prev) => ({
        ...prev,
        pincode: value,
      }));
  };

  const handleAddressLine1Change = (e) => {
    const { value } = e.target;
    setAccountSettingFormErrors(initialErrorStates);
    setAccountSettingFormStates((prev) => ({
      ...prev,
      permanantAddressLine1: value,
    }));
  };
  const handleAddressLine2Change = (e) => {
    const { value } = e.target;
    setAccountSettingFormErrors(initialErrorStates);

    setAccountSettingFormStates((prev) => ({
      ...prev,
      permanantAddressLine2: value,
    }));
  };

  const [accountSettingFormErrors, setAccountSettingFormErrors] =
    useState(initialErrorStates);

  const onProfileUpdate = () => {
    let errobj = Validation(
      {
        email: accountSettingFormStates.email,
        pincode: accountSettingFormStates.pincode,
        state: accountSettingFormStates.state.name,
        city: accountSettingFormStates.city.name,
      },
      validationRules
    );
    setAccountSettingFormErrors(errobj);
    if (Object.values(errobj).filter(Boolean).length === 0) {
      handleSubmit();
    }
  };

  const sendOtpToEmail = async () => {
    let payload = { email: accountSettingFormStates.email, type: "send" };
    return await verifyEmailOTP(payload);
  };

  const onVerifyClick = async () => {
    setIsLoading(true);
    try {
      let res = await sendOtpToEmail();
      if (res.status_code === 200) {
        customToastr({
          text: "OTP sent to the e-mail address",
          type: "success",
          positionClass: tcs["toast-top-center2"],
          toastClass: tcs["email-otp-toastr"],
        });
        setShowVerifyEmailModal(true);
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useMemo(async () => {
    if (accountSettingFormStates?.pincode?.length === 6) {
      const res = await getCityStateFromPincode(
        `/${accountSettingFormStates.pincode}`
      );
      if (res?.status_code === 200) {
        setAccountSettingFormStates((prev) => ({
          ...prev,
          city: { name: res.data.city_name, id: res.data.city_id },
          state: { name: res.data.state_name, id: res.data.state_id },
        }));
      } else {
        setAccountSettingFormStates((prev) => ({
          ...prev,
          city: { name: "", id: "" },
          state: { name: "", id: "" },
        }));
      }
    } else {
      if (
        accountSettingFormStates.city.name &&
        accountSettingFormStates.state.name
      ) {
        setAccountSettingFormStates((prev) => ({
          ...prev,
          city: { name: "", id: "" },
          state: { name: "", id: "" },
        }));
      }
    }
  }, [accountSettingFormStates.pincode]);

  const sentEmailVerficationlink = async () => {
    setIsLoading(true);
    try {
      const resultResponse = await LoginServices.sendEmailVerifyLink({
        email: accountSettingFormStates.email,
      });
      if (resultResponse?.data?.status_code === 200) {
        setShowEmailSentPopUp(true);
      }
      if (!resultResponse) {
        setAccountSettingFormErrors((prev) => ({
          ...prev,
          email: "This email is already registered.",
        }));
      }
    } catch (ex) {
      console.log("Error", ex);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="stdFontStyle font-size16 letterSpacing015  line-height19 ">
      {!hideHeaderFooter && (
        <>
          {!isMobile && <DesktopHeader />}
          {isLoading && <Loader isLoading={isLoading} />}
          {isMobile ? (
            <HeaderBackground
              className={`borderRadiusBottom12 padding-right19 padding-left21 linear-gradient1 
        ${isMobile ? s["header-height"] : "margin-bottom24"}
        `}
            >
              <Loader isLoading={isLoading} />
              <Header
                title="Account Settings"
                backArrow={backArrow}
                onSecondaryIconClick={null}
                onBackArrowClick={() => history.goBack()}
                className="text-dark padding-bottom16 padding-top20"
              />
            </HeaderBackground>
          ) : (
            <BreadcrumbUI
              onBackArrowClick={() => history.goBack()}
              items={[
                { text: "My Profile", url: () => history.push("/my-profile") },
                {
                  text: "Settings",
                  url: "/manage-profile",
                },
                {
                  text: "General Settings",
                },
              ]}
            />
          )}
        </>
      )}
      <div
        className={
          isMobile
            ? ""
            : hideHeaderFooter
            ? ""
            : `d-width-2-col mx-auto margin-top24`
        }
      >
        <ProfileCard
          showKyc={!(userDetails?.kyc === 1 || userDetails?.kyc === "1")}
          profileVerified={userDetails?.kyc === 1 || userDetails?.kyc === "1"}
          title={`${userDetails?.first_name} ${userDetails?.last_name || ""}`}
          designationClassName={
            userDetails?.kyc === 1 || userDetails?.kyc === "1"
              ? `margin-bottom16`
              : ``
          }
          kycNote={"KYC Pending"}
          showKycArrow={false}
          // onCoverEditButtonClick={() => setShow(true)}
          // onProfileEditButtonClick={() => setShow(true)}
          cardClassName={`${s["box-shadow-unset"]} ${s["margin-bottom24"]}`}
          showOptions={false}
          designation={isMobile ? undefined : userDetails?.user_type}
        />
        <div className={isMobile ? "padding16 padding-bottom16" : ` mx-auto`}>
          <AccountSettingForm
            handleSubmit={handleSubmit}
            onVerifyClick={sentEmailVerficationlink}
            errorMessage={errorMessage}
            disableSave={disableSave}
            setIsLoading={setIsLoading}
            onUserDetailsAPI={getUserDetailsAPI}
            data={accountSettingFormStates}
            setData={setAccountSettingFormStates}
            {...{
              isEmailVerified: accountSettingFormStates.isEmailVerified,
              rmName: accountSettingFormStates.rmName,
              rmEmail: accountSettingFormStates.rmEmail,
              rmPhone: accountSettingFormStates.rmPhone,
              email: accountSettingFormStates.email,
              pincode: accountSettingFormStates?.pincode,
              state: accountSettingFormStates?.state,
              city: accountSettingFormStates?.city,
              handleEmailChange,
              handlePincode,
              hideDetails: accountSettingFormStates?.hideDetails,
              permanantAddressLine1:
                accountSettingFormStates?.permanantAddressLine1,
              permanantAddressLine2:
                accountSettingFormStates?.permanantAddressLine2,
              handleSwitchChange,
              handleAddressLine1Change,
              handleAddressLine2Change,
              onProfileUpdate,
              accountSettingFormErrors,
              isValidEmailRegex: isValidEmailRegex,
              childEntities: accountSettingFormStates?.childEntities,
              contactDetails: accountSettingFormStates?.contactDetails,
              usersData: accountSettingFormStates?.usersData,
            }}
          />
          {showVerifyEmailModal && (
            <VerifyEmailModal
              getUserDetailsAPI={getUserDetailsAPI}
              showVerifyEmailModal={showVerifyEmailModal}
              setShowVerifyEmailModal={setShowVerifyEmailModal}
              sendOtpToEmail={sendOtpToEmail}
              accountSettingFormStates={accountSettingFormStates}

              // verifyOtp={verifyOtp}
              // verifyingOtp={verifyingOtp}
            />
          )}

          {showEmailSentPopUp && (
            <EmailLinkSentPopUp
              setShowEmailSentPopUp={setShowEmailSentPopUp}
              showEmailSentPopUp={showEmailSentPopUp}
              email={accountSettingFormStates?.email ?? ""}
            />
          )}
        </div>
      </div>
      {!hideHeaderFooter && (
        <>{isMobile ? <StickyFooter /> : <DesktopFooter />}</>
      )}
    </div>
  );
};

export default AccountSettings;
