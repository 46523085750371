import React, { useEffect, useState } from "react";
import HeadLines from "../../CommonComponents/HeadLines";
import { isMobile } from "../../helperFunc";
import InputFieldWithTitle from "../../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import CustomReactSelect from "../../CommonComponents/CustomReactSelect/CustomReactSelect";
import HeadLinesSpan from "../../CommonComponents/HeadlinesSpan";
import style from "../style.module.css";
import { errorInfoIcon } from "../../icon_assets";
import CustomCheckBox from "../../CommonComponents/CustomCheckBox/CustomCheckBox";

const UserComponent = ({
  data,
  newUser,
  userErrors,
  handleUserInputChange,
  langArray,
  setNewUser,
}) => {

  const [entityEmailCheckBox,setEntityEmailCheckBox] = useState(false);

  useEffect(()=>{
    if(entityEmailCheckBox){
      handleUserInputChange({target:{name:"userEmail",value:data?.entityEmail}})
    }
  },[entityEmailCheckBox])

  

  return (
    <div className={`${isMobile ? `` : `${style["userdiV"]}`}`}>
      <HeadLines
        text="Users"
        className={`margin-bottom8 ${
          isMobile
            ? `font-weight500 font-size16 line-height19 letterSpacing025 textColorBlack`
            : `font-weight500 font-size16 line-height19 letterSpacing001 ${style["colorLightBlack"]}`
        }`}
      />
      <HeadLines
        text="This user will login on arya.ag"
        className={`margin-bottom20 font-weight500 font-size16 line-height19 letterSpacing001 colorDark9`}
      />
      <div
        className={
          isMobile
            ? `d-flex align-items-center flex-d-col gap16`
            : style["userContainer"]
        }
      >
        <InputFieldWithTitle
          titleClassName={`${
            isMobile
              ? `letterSpacing025 font-weight500 font-size16 line-height19 colorDark9`
              : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
          }`}
          title={"Name"}
          disabled={false}
          isCompulsory={true}
          compulsoryStarClassName="line-height14"
          inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
            style["inputBorder"]
          } font-weight400 ${
            isMobile ? "font-size14 line-height16" : "font-size16 line-height19"
          } letterSpacing001`}
          errorInputClassName={"input-style-error"}
          errorClassName={"input-error-style margin-top4"}
          showError={userErrors?.userName ? userErrors?.userName : ""}
          errorIcon={errorInfoIcon}
          value={newUser.userName}
          name="userName"
          onChange={handleUserInputChange}
          marginCSS={style["marginb0"]}
          className="width-100"
        />
        <div className="w-100">
          <InputFieldWithTitle
            titleClassName={`${
              isMobile
                ? `letterSpacing025 font-weight500 font-size16 line-height19 colorDark9`
                : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
            }`}
            title={"Phone"}
            disabled={true} // Phone is disabled with default value
            isCompulsory={true}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile ? "font-size14 line-height16" : "font-size16 line-height19"
            } letterSpacing001`}
            value={newUser.userPhone}
            name="userPhone"
            marginCSS={style["marginb0"]}
            className="width-100"
          />
           
        </div>
        <div className="w-100">
           <InputFieldWithTitle
            titleClassName={`${
              isMobile
                ? `letterSpacing025 font-weight500 font-size16 line-height19 colorDark9`
                : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
            }`}
            title={"Email"}
            disabled={false}
            isCompulsory={false}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile ? "font-size14 line-height16" : "font-size16 line-height19"
            } letterSpacing001`}
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style margin-top4"}
            showError={userErrors?.userEmail ? userErrors?.userEmail : ""}
            errorIcon={errorInfoIcon}
            value={newUser.userEmail}
            name="userEmail"
            onChange={handleUserInputChange}
            marginCSS={style["marginb0"]}
            className="width-100"
          />
           <div
                  className={`d-flex align-items-center gap8 margin-top8  `}
                >
                  <CustomCheckBox
                    id="EntityEmail"
                    inputProps={{
                      name: "EntityEmail",
                      checked: entityEmailCheckBox,
                      onChange: () => setEntityEmailCheckBox(!entityEmailCheckBox),
                    }}
                    className={style["mb2"]}
                  />
                  <HeadLines
                    className={`${
                      isMobile
                        ? ` font-size14 line-height16 `
                        : "font-size16 line-height19 "
                    } letterSpacing05 ${style["color333333"]}`}
                    text={`Same as Entity Email`}
                  />
                  {/* <Switch
                      id="show-tan-switch"

                      checked={showTanNumber}
                      onChange={() => setShowTanNumber(!showTanNumber)}

                    /> */}
                </div>
        </div>
      </div>
      <div className={`margin-top16 width-100`}>
        <HeadLinesSpan
          text="Select preferred language to receive communication"
          className={`colorDark9 ${
            isMobile
              ? "font-weight500 font-size16 line-height19"
              : "font-size16 line-height19 font-weight600"
          } letterSpacing025 `}
        />
        <CustomReactSelect
          className={`width-100 lightBorder boxRadius4 ${
            style["textColorBlack"]
          } ${
            isMobile
              ? "margin-top8"
              : `${style["height43"]} margin-top4 ${style["height43Imp"]}`
          }`}
          options={langArray}
          onChange={(selectedOption) =>
            setNewUser((prev) => ({
              ...prev,
              comLang: selectedOption,
            }))
          }
          value={newUser.comLang}
        />
      </div>
      <div className={`margin-top16 width-100`}>
        <HeadLinesSpan
          text="Choose preferred language to view content on arya.ag"
          className={`colorDark9 ${
            isMobile
              ? "font-weight500 font-size16 line-height19"
              : "font-size16 line-height19 font-weight600"
          } letterSpacing025`}
        />
        <CustomReactSelect
          className={`width-100 lightBorder boxRadius4 ${
            style["textColorBlack"]
          } ${
            isMobile
              ? "margin-top8"
              : `${style["height43"]} margin-top4 ${style["height43Imp"]}`
          }`}
          options={langArray}
          onChange={(selectedOption) =>
            setNewUser((prev) => ({
              ...prev,
              contentLang: selectedOption,
            }))
          }
          value={newUser.contentLang}
        />
      </div>
    </div>
  );
};

export default UserComponent;
