import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import HeadLines from "../CommonComponents/HeadLines";
import { isMobile } from "../helperFunc";
import { errorInfoIcon } from "../icon_assets";
import InputFieldWithTitle from "../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import TextAreaWithTitle from "../CommonComponents/TextAreaWithTitle/TextAreaWithTitle";
import textAreaStyles from "../CommonComponents/TextAreaWithTitle/styles.module.css";
import { useTranslation } from "react-i18next";
import { CommonApiService } from "../../../../_services";

const HeadOfficeAddressDetail = ({ error, data, onChange=()=>{},gstDoc=false,setData=()=>{} }) => {
  const { t: translation } = useTranslation();

  const Pincode = (value) => {
    CommonApiService.getAddressByPincode(value).then((res) => {
      if (res?.data?.data) {
        const result = res.data.data;
        setData((prev) => ({
          ...prev,
          ["kycState"]: result.state_name,
          // ["city"]: result.city_name,
          // ["city_id"]: result.city_id,
          // ["state_id"]: result.state_id,
        }));
      } else {
        setData((prev) => ({
          ...prev,
          ["kycState"]: "",
          // ["city"]: "",
          // ["city_id"]: "",
          // ["state_id"]: "",
        }));
        // setPincodeError("Invalid Pincode");
      }
    });
  };

  const onChangePincode = (e)=>{
      const { name, value } = e.target;
    
      if (value.length == 6) {
        Pincode(value);
      }
      onChange(e);

  }


  return (
    <>
     
      <div
        className={` boxRadius8 ${
          isMobile ? `${style["mobBillingDiv"]}` : `${style["billingdiV"]}`
        } `}
      >
        <HeadLines
          text="Head Office Address"
          className={`${style["marginBottom22"]} ${
            isMobile
              ? `font-weight400 font-size14 line-height16 letterSpacing025 textColorBlack`
              : `font-weight500 font-size18 line-height21 letterSpacing001 ${style["colorLightBlack"]}`
          }`}
        />

        <InputFieldWithTitle
          titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
            isMobile ? `font-weight500` : `font-weight600`
          }`}
          title={"Pincode"}
          disabled={gstDoc}
          isCompulsory={true}
          compulsoryStarClassName="line-height14"
          inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
            style["inputBorder"]
          } font-weight400 ${
            isMobile ? "font-size14 line-height16" : "font-size16 line-height19"
          } letterSpacing001 `}
          errorInputClassName={"input-style-error"}
          disableInputClassName={`input-style-disable`}
          errorClassName={"input-error-style margin-top4"}
          showError={error?.kycPincode ? error.kycPincode : ""}
          errorIcon={errorInfoIcon}
          value={data.kycPincode}
          name="kycPincode"
          onChange={onChangePincode}
          marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
        />

        <div
          className={`d-flex ${isMobile ? "gap16 flex-d-col" : "width-100"}`}
        >
          <InputFieldWithTitle
            showVerifyText={false}
            titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
              isMobile ? `font-weight500` : `font-weight600`
            }`}
            title={"City"}
            disabled={gstDoc}
            isCompulsory={true}
            onChange={onChange}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? "font-size14 line-height16"
                : "font-size16 line-height19"
            } letterSpacing001`}
            disableInputClassName={`input-style-disable`}
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style margin-top4"}
            showError={error?.kycCity ? error.kycCity : ""}
            errorIcon={errorInfoIcon}
            value={data.kycCity}
            name="kycCity"
            className={isMobile ? "" : "width-100"}
            marginCSS={isMobile ? "" : "margin-bottom24"}
          />
          <InputFieldWithTitle
            showVerifyText={false}
            titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
              isMobile ? `font-weight500` : `font-weight600`
            }`}
            title={"State"}
            disabled={true}
            isCompulsory={true}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? "font-size14 line-height16"
                : "font-size16 line-height19"
            } letterSpacing001`}
            disableInputClassName={`input-style-disable`}
            errorInputClassName={"input-style-error "}
            errorClassName={"input-error-style margin-top4"}
            showError={error?.kycState ? error.kycState : ""}
            errorIcon={errorInfoIcon}
            value={data.kycState}
            name="kycState"
            className={isMobile ? "" : "width-100 margin-left20"}
            marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
          />
        </div>

        <TextAreaWithTitle
          titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
            isMobile ? `font-weight500` : `font-weight600`
          }`}
          title="Full Address"
          showWordCount
          isCompulsory={true}
          compulsoryStarClassName="line-height14"
          // inputClassName={`${styles["input-style"]}  margin-top5`}
          // disableInputClassName={`${styles["input-style-disable"]}`}
          errorInputClassName={`input-style-error`}
          errorClassName={
            isMobile ? `input-error-style ` : style["desk-input-error-style"]
          }
          errorIconClassName={`margin-right4 ${
            !isMobile && style["desk-info-error-icon"]
          } `}
          showError={error?.kycAddress ? error.kycAddress : ""}
          errorIcon={errorInfoIcon}
          minLength={10}
          isLengthParenthesis={false}
          countClassName={textAreaStyles["word-count"]}
          textAreaClassName={`${textAreaStyles["textarea-style"]} ${gstDoc?textAreaStyles["input-style-disable"]:""} ${style["height-80"]} margin-top5 margin-top8  `}
          textAreaProps={{
            placeholder: `${translation("Type Text Here....")}`,
            name: "kycAddress",
            value: data.kycAddress,
            onChange: onChange,
            maxLength: 300,
            disabled:gstDoc
          }}
        />

         </div>

         <div
        className={`
          ${isMobile
            ? `d-flex align-items-center flex-d-col gap16`
            : style["userContainer"]
          } padding-top16
        `}
      >
       
        <InputFieldWithTitle
          titleClassName={`${
            isMobile
              ? `letterSpacing025 font-weight500 font-size16 line-height19 colorDark9`
              : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
          }`}
          title={"Entity Mobile Number"}
          disabled={false} // Phone is disabled with default value
          isCompulsory={true}
          compulsoryStarClassName="line-height14"
          inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
            style["inputBorder"]
          } font-weight400 ${
            isMobile ? "font-size14 line-height16" : "font-size16 line-height19"
          } letterSpacing001`}
          errorInputClassName={"input-style-error"}
          errorClassName={"input-error-style margin-top4"}
          showError={error?.entityPhone ? error?.entityPhone : ""}
          errorIcon={errorInfoIcon}
          value={data.entityPhone}
          name="entityPhone"
          marginCSS={style["marginb0"]}
          className="width-100"
          onChange={onChange}
        />
        <InputFieldWithTitle
          titleClassName={`${
            isMobile
              ? `letterSpacing025 font-weight500 font-size16 line-height19 colorDark9`
              : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
          }`}
          title={"Entity Email"}
          disabled={false}
          isCompulsory={true}
          compulsoryStarClassName="line-height14"
          inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
            style["inputBorder"]
          } font-weight400 ${
            isMobile ? "font-size14 line-height16" : "font-size16 line-height19"
          } letterSpacing001`}
          errorInputClassName={"input-style-error"}
          errorClassName={"input-error-style margin-top4"}
          showError={error?.entityEmail ? error?.entityEmail : ""}
          errorIcon={errorInfoIcon}
          value={data.entityEmail}
          name="entityEmail"
          onChange={onChange}
          marginCSS={style["marginb0"]}
          className="width-100"
        />
      </div>

        <div className={isMobile ? style["moblineCss2"] : style["lineCss2"]} />

    </>
  );
};

export default HeadOfficeAddressDetail;

