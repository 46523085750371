export const servicesArray = [
  {
    title: "Storage & Logistics",
    onClick: "/our-services/storage-logistics",
  },
  {
    title: "Audit",
    onClick: "/our-services/Audits-Serveillance",
  },
  {
    title: "Procurement Facilitation",
    onClick: "/arjun",
  },
  {
    title: "Consulting",
    onClick: "/our-services/Consulting",
  },
  {
    title: "Arya.ag Impact Performance Report’24",
    onClick:
      "https://storage.googleapis.com/a2z-web/arya/Arya.ag%20Impact%20Performance%20Report%202024.pdf",
    differentTab: true,
  },
  {
    title: "Arya Collateral Warehousing Services Pvt. Ltd. CSR Policy",
    onClick:
      "https://storage.googleapis.com/a2z-web/arya/CSR-Policy-Arya%20Collateral.pdf",
    differentTab: true,
  },

  // {
  //   title: "TransUnion Consumer : Consent Withdrawal Process (English)",
  //   onClick:
  //     "https://storage.googleapis.com/a2z-web/arya/transunion/Commercial%20Consent.docx",
  //   differentTab: true,
  // },
  // {
  //   title: "Grievance Redressal Process",
  //   onClick:
  //     "https://storage.googleapis.com/a2z-web/arya/transunion/Grievance%20Redressal%20and%20Consent%20Withdrawal%20Policy.pdf",
  //   differentTab: true,
  // },
];

export const GetToKnowUs = [
  {
    title: "About Us",
    onClick: "/our-story",
  },
  {
    title: "Our Services",
    onClick: "/who-we-serve",
  },
  {
    title: "Careers",
    onClick: "/career",
  },
  // {
  //   title: "Careers",
  //   onClick: "/",
  // },
];
