import React, { useEffect, useState } from "react";
import InputFieldWithTitle from "../../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import { errorInfoIcon } from "../../icon_assets";
import CustomReactSelect from "../../CommonComponents/CustomReactSelect/CustomReactSelect";
import HeadLinesSpan from "../../CommonComponents/HeadlinesSpan";
import { isMobile } from "../../helperFunc";
import style from "./style.module.css";
import HeadLines from "../../CommonComponents/HeadLines";
import Button from "../../CommonComponents/Button";
import CommonTable from "../BillingInfoForm/CommonTable/CommonTable";
import { customToastr } from "../../CustomToastr";
import { postUserDetails } from "../../../../../_services/Arya2.0/AccountSettings";
import ErrorMessage from "../../CommonComponents/ErrorMessage/ErrorMessage";
import Loader from "../../CommonComponents/Loader/Loader";
import HeadOfficeAddressDetail from "../../Registration/HeadOfficeAddressDetail";
export const headers = [
  "Name",
  "Phone",
  "Email",
  "Communication Language",
  "View Content",
  "Actions",
];
const UserInfoForm = ({ usersData,data,setData }) => {
  
  const langArray = [
    { label: "English", value: "en" },
    { label: "Hindi", value: "hi" },
    { label: "Telugu", value: "te" },
    { label: "Marathi", value: "mr" },
    { label: "Gujarati", value: "gu" },
  ];
  
  const [newUser, setNewUser] = useState({
    userName: "",
    contentLang: "",
    comLang: "",
    userEmail: "",
    userPhone: "",
  });
  const [isDisable, setIsDisable] = useState(true);
  const [userErrors, setUserErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [commonError, setCommonErrors] = useState("");
  const [contactPersons, setContactPersons] = useState([]);
  const [contactTableData, setContactTableData] = useState([]);
  const validateUser = (user) => {
    const newErrors = {};

    // Ensure userName exists and trim it
    const trimmedUserName = user?.userName?.trim();

    // Validate userName
    if (!trimmedUserName) {
      newErrors.userName = "Name is required.";
    }

    // Validate userEmail if provided
    if (user?.userEmail && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(user.userEmail)) {
      newErrors.userEmail = "Enter a valid email address.";
    }

    if(!data?.entityPhone){
      newErrors.entityPhone = "Email is required.";
    }else if(!/^[0-9]{10}$/.test(data?.entityPhone)){
      newErrors.entityPhone = "Enter a valid phone number.";
    }

    if(!data?.entityEmail){
      newErrors.entityEmail = "Email is required.";
    }else if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(data.entityEmail)) {
      newErrors.entityEmail = "Enter a valid email address.";
    }


    return newErrors;
  };

  const handleUserInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) return;
    if(name!=="userName" || (name==="userName" && /^[a-zA-Z\s]*$/.test(value))){
      setNewUser((prev) => {
        const updatedUser = {
          ...prev,
          [name]: value,
        };

        return updatedUser;
      });
    }
  };

  const handleEntityInputChange = (e) => {
    const { name, value } = e.target;
   
      setData((prev) => {
        const updatedUser = {
          ...prev,
          [name]: value,
        };

        return updatedUser;
      });
    
  };

  const isDuplicateContact = (phone, email) =>
    contactPersons.some(
      (contact) => contact.phone === phone && contact.email === email
    );
  useEffect(() => {
    if (usersData && usersData?.length > 0) {
      const dataObj = usersData[0];
      let communicationlanguage;
      let applanguage;
      if (dataObj?.primaryPreferences?.length > 0) {
        const preferenceMap = Object.fromEntries(
          dataObj.primaryPreferences.map((pref) => [pref.key, pref.value])
        );

        applanguage =
          langArray.find((lang) => lang.value === preferenceMap["APP_LANG"]) ||
          null;

        communicationlanguage =
          langArray.find(
            (lang) => lang.value === preferenceMap["NOTIFICATION_LANG"]
          ) || null;
      }

      setNewUser({
        userName: dataObj?.personalDetails
          ? `${
              dataObj?.personalDetails?.firstName
                ? dataObj?.personalDetails?.firstName
                : ""
            } ${
              dataObj?.personalDetails?.lastName
                ? dataObj?.personalDetails?.lastName
                : ""
            }`
          : "",
        userPhone: dataObj?.phoneNumber ? dataObj?.phoneNumber : "",
        userEmail: dataObj?.personalDetails
          ? dataObj?.personalDetails.email
          : "",
        contentLang: applanguage,
        comLang: communicationlanguage,
      });
    }
  }, [usersData]);
  useEffect(() => {
    const validationErrors = validateUser(newUser);
    if (Object.keys(validationErrors).length > 0) {
      //   setUserErrors(validationErrors);
      setIsDisable(true);
    } else {
      setIsDisable(false);

      setUserErrors({});
    }
  }, [newUser]);
  const handleSaveUserInfo = async () => {
    setCommonErrors("");
    const validationErrors = validateUser(newUser);
    if (Object.keys(validationErrors).length > 0) {
      setUserErrors(validationErrors);
    } else {
      setUserErrors({});
      const dataObj = {
        email: newUser?.userEmail || "",
        entity_email: data.entityEmail,
        entity_mobile:  data.entityPhone,
        is_entity_update: data.entityEmail != data?.apiEntityEmail || data.entityPhone != data?.apiEntityPhone,
        user: {
          personalDetails: {
            name: newUser?.userName || "",
            email: newUser?.userEmail || "",
          },
          primaryPreferences: {
            notification_lang: newUser?.comLang?.value
              ? newUser?.comLang?.value
              : "",
            app_lang: newUser?.contentLang?.value
              ? newUser?.contentLang?.value
              : "",
          },
        },
      };
      setIsLoading(true);
      const apiResponse = await postUserDetails(dataObj);
      setIsLoading(false);

      if (apiResponse?.status_code === 200) {
        customToastr({
          text: "Details saved successfully",
          type: "success",

          timeOut: 1,
        });
      } else {
        if (
          (apiResponse?.status_code > 400 && apiResponse?.status_code <= 418) ||
          apiResponse?.status_code >= 420
        ) {
          setCommonErrors(
            apiResponse?.message
              ? Array.isArray(apiResponse?.message)
                ? apiResponse?.message[0]
                : apiResponse.message
              : apiResponse?.msg
              ? apiResponse?.msg
              : ""
          );
        }
      }
    }
  };
  return (
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      <HeadOfficeAddressDetail
              error={userErrors}
              data={data}
              onChange={handleEntityInputChange}
              gstDoc={true}
              // setData={setData}
            />

      <div className={`${isMobile ? `` : `${style["userdiV"]}`}`}>
        <HeadLines
          text="Users"
          className={`margin-bottom8 ${
            isMobile
              ? `font-weight500 font-size16 line-height19 letterSpacing025 ${style["color404545"]}`
              : `font-weight500 font-size16 line-height19 letterSpacing001 ${style["colorLightBlack"]}`
          }`}
        />
        <HeadLines
          text="This user will login on arya.ag"
          className={`margin-bottom20  colorDark9 ${
            isMobile
              ? "font-weight500 font-size16 line-height19 letterSpacing025"
              : "font-weight500 font-size16 line-height19 letterSpacing001"
          }`}
        />
        <div
          className={
            isMobile
              ? `d-flex align-items-center flex-d-col gap16`
              : style["userContainer"]
          }
        >
          <InputFieldWithTitle
            titleClassName={`${
              isMobile
                ? `letterSpacing025 font-weight500 font-size14 line-height16 colorDark9`
                : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
            }`}
            title={"Name"}
            disabled={false}
            isCompulsory={true}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? "font-size14 line-height16"
                : "font-size16 line-height19"
            } letterSpacing001`}
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style margin-top4"}
            showError={userErrors?.userName ? userErrors?.userName : ""}
            errorIcon={errorInfoIcon}
            value={newUser.userName}
            name="userName"
            onChange={handleUserInputChange}
            marginCSS={style["marginb0"]}
            className="width-100"
          />
          <InputFieldWithTitle
            titleClassName={`${
              isMobile
                ? `letterSpacing025 font-weight500 font-size14 line-height19 colorDark9`
                : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
            }`}
            title={"Phone"}
            disabled={true} // Phone is disabled with default value
            isCompulsory={true}
            disableInputClassName={`${style["input-style-disable"]}`}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? "font-size14 line-height16"
                : "font-size16 line-height19"
            } letterSpacing001`}
            value={newUser.userPhone}
            name="userPhone"
            marginCSS={style["marginb0"]}
            className="width-100"
          />
          <InputFieldWithTitle
            titleClassName={`${
              isMobile
                ? `letterSpacing025 font-weight500 font-size14 line-height19 colorDark9`
                : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
            }`}
            title={"Email"}
            disabled={false}
            isCompulsory={false}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? "font-size14 line-height16"
                : "font-size16 line-height19"
            } letterSpacing001`}
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style margin-top4"}
            showError={userErrors?.userEmail ? userErrors?.userEmail : ""}
            errorIcon={errorInfoIcon}
            value={newUser.userEmail}
            name="userEmail"
            onChange={handleUserInputChange}
            marginCSS={style["marginb0"]}
            className="width-100"
          />
        </div>
        <div className={`margin-top16 width-100`}>
          <HeadLinesSpan
            text="Select preferred language to receive communication"
            className={`colorDark9 ${
              isMobile
                ? "font-weight500 font-size14 line-height19"
                : "font-size16 line-height19 font-weight600"
            } letterSpacing025`}
          />
          <CustomReactSelect
            className={`width-100 lightBorder boxRadius4 ${
              style["textColorBlack"]
            } ${
              isMobile
                ? "margin-top8"
                : `${style["height43"]} margin-top4 ${style["height43Imp"]}`
            }`}
            options={langArray}
            onChange={(selectedOption) =>
              setNewUser((prev) => ({
                ...prev,
                comLang: selectedOption,
              }))
            }
            value={newUser.comLang}
          />
        </div>
        <div className={`margin-top16 width-100`}>
          <HeadLinesSpan
            text="Choose preferred language to view content on arya.ag"
            className={`colorDark9 ${
              isMobile
                ? "font-weight500 font-size14 line-height19"
                : "font-size16 line-height19 font-weight600"
            } letterSpacing025`}
          />
          <CustomReactSelect
            className={`width-100 lightBorder boxRadius4 ${
              style["textColorBlack"]
            } ${
              isMobile
                ? "margin-top8"
                : `${style["height43"]} margin-top4 ${style["height43Imp"]}`
            }`}
            options={langArray}
            onChange={(selectedOption) =>
              setNewUser((prev) => ({
                ...prev,
                contentLang: selectedOption,
              }))
            }
            value={newUser.contentLang}
          />
        </div>
        <div>
          {commonError && (
            <ErrorMessage className={"mt-2"} text={commonError} />
          )}
        </div>
        <div className="d-flex justify-content-center">
          <Button
            onClick={handleSaveUserInfo}
            className={` ${isDisable && `${style["grayButton"]}`}
                ${isMobile ? style["mobAddButton"] : style["addButton"]}`}
            title="Save"
            disabled={isDisable}
          />
        </div>
        {/* {isMobile ? (
        //   <MobileTableView data={contactTableData} />
        <></>
      ) : (
        <span className={style.contactTable}>
          <CommonTable
            headers={headers}
            subLabel={false}
            tableClassName={style.tableClassName}
            data={contactTableData ? contactTableData : []}
            tableCell={style.tableCell}
            actionComponents={[<ActionButton key="action-button" />]}
          />
        </span>
      )} */}
      </div>
    </>
  );
};
export default UserInfoForm;
const handleEdit = (row) => {
  console.log("Edit clicked for:", row);
};

const handleDelete = (row) => {
  console.log("Delete clicked for:", row);
};

const ActionButton = ({ data, rowIndex }) => (
  <div style={{ display: "flex", gap: "10px" }}>
    <button onClick={() => handleEdit(data)}>
      {/* <img src={EditIcon} alt="Edit" /> */}
    </button>
    <button onClick={() => handleDelete(data)}>
      {/* <img src={DeleteIcon} alt="Delete" /> */}
    </button>
  </div>
);
