import Youtube from "./../Images/Footer/Youtube.png";
import LinkedIn from "./../Images/Footer/LinkedIn.png";
import Instagram from "./../Images/Footer/Instagram.png";
import Facebook from "./../Images/Footer/Facebook.png";
import Twitter from "./../Images/Footer/Twitter.png";

export const footerData = [
  {
    title: "arya.ag",
    subTitleArray: [
      {
        title:
          "India’s largest integrated grain commerce platform. We help buyers and sellers find the best prices for their commodities.",
        onClick: "",
      },
    ],
  },
  {
    title: "Quick Links",
    subTitleArray: [
      {
        title: "About Us",
        onClick: "/our-story",
      },
      {
        title: "Our Services",
        onClick: "/who-we-serve",
      },
      {
        title: "Careers",
        onClick: "/career",
      },
      {
        title: "Storage & Logistics",
        onClick: "/our-services/storage-logistics",
      },
      {
        title: "Audit",
        onClick: "/our-services/Audits-Serveillance",
      },
      {
        title: "Procurement Facilitation",
        onClick: "/arjun",
      },
      {
        title: "Consulting",
        onClick: "/our-services/Consulting",
      },
      {
        title: "Arya.ag Impact Performance Report’24",
        onClick:
          "https://storage.googleapis.com/a2z-web/arya/Arya.ag%20Impact%20Performance%20Report%202024.pdf",
      },
      {
        title: "Arya Collateral Warehousing Services Pvt. Ltd. CSR Policy",
        onClick:
          "https://storage.googleapis.com/a2z-web/arya/CSR-Policy-Arya%20Collateral.pdf",
      },
      // {
      //   title: "TransUnion Consumer : Consent Withdrawal Process (English)",
      //   onClick:
      //     "https://storage.googleapis.com/a2z-web/arya/transunion/Commercial%20Consent.docx",
      // },
      // {
      //   title: "Grievance Redressal Process",
      //   onClick:
      //     "https://storage.googleapis.com/a2z-web/arya/transunion/Grievance%20Redressal%20and%20Consent%20Withdrawal%20Policy.pdf",
      // },
    ],
  },
  {
    title: "Our Entities",
    subTitleArray: [
      {
        title: "Arya Collateral Warehousing Services Pvt. Ltd",
        onClick: "",
      },
      {
        title: "For queries, contact:",
        onClick: "",
      },
      {
        title: "Prakash Shukla",
        onClick: "",
      },
      {
        title: "Contact Number: +91-120 4859355",
        onClick: "tel:+911204859355",
      },
      {
        title: "Aryadhan Financial Solutions Private Limited",
        onClick: "",
      },
      {
        title: "Grievance Redressal Officer:",
        onClick: "",
      },
      {
        title: "Nitendra Gupta",
        onClick: "",
      },
      {
        title: "Contact Number: 0120-4859319",
        onClick: "tel:+01204859319",
      },
      {
        title: "Aryatech Platforms Private Limited",
        onClick: "",
      },
      {
        title: "For queries, contact:",
        onClick: "",
      },
      {
        title: "Prakash Shukla",
        onClick: "",
      },
      {
        title: "Contact Number: +91-120 4859355",
        onClick: "tel:+911204859355",
      },
    ],
  },

  // {
  //   title: "About arya.ag",
  //   subTitleArray: [
  //     {
  //       title: "Our team",
  //       onClick: "",
  //     },
  //     {
  //       title: "Careers",
  //       onClick: "",
  //     },
  //     {
  //       title: "Press",
  //       onClick: "",
  //     },
  //   ],
  // },
  {
    title: "Contact",
    subTitleArray: [
      {
        title: "contact@arya.ag",
        onClick: "",
      },
      {
        title: "+91 9015260260",
        onClick: "tel:+919015260260",
      },
      {
        title: "H-82,Sector-63, Noida, Uttar Pradesh 201301",
        onClick: "",
      },
    ],
  },
  // {
  //   title: "Grievance Redressal Officer",
  //   subTitleArray: [
  //     {
  //       title: "Nitendra Gupta",
  //       onClick: "",
  //     },
  //     {
  //       title: "0120-4859319",
  //       onClick: "tel:01204859319",
  //     },
  //   ],
  // },
  {
    title: "Other Links",
    subTitleArray: [
      {
        title: "Terms and conditions",
        onClick: "/terms-condition",
      },
      {
        title: "Privacy Policy",
        onClick: "/privacy-policy",
      },
    ],
  },
];
export const socialIcons = [
  {
    icon: Youtube,
    link: "https://www.youtube.com/channel/UCIM_C6FrdqGjfQjrY4fNRSw",
    height: 20,
  },
  {
    icon: Twitter,
    link: "https://twitter.com/aryadotag",
  },
  {
    icon: Facebook,
    link: "https://www.facebook.com/aryadotag",
  },
  {
    icon: Instagram,
    link: "https://www.instagram.com/aryadotag/",
  },
  {
    icon: LinkedIn,
    link: "https://www.linkedin.com/company/aryadotag/",
    height: 20,
  },
];
